import React from 'react';
import { css, styled } from 'styled-components';
import Link, { LinkProps } from './Link';

interface ButtonProps extends LinkProps {
  children: React.ReactNode,
  $ghost?: boolean,
  $inverted?: boolean,
}

const Button = styled(Link)<ButtonProps>`
  background-color: ${props => props.$inverted ? 'var(--color-white)' : 'var(--color-downriver-400)'};
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  color: ${props => props.$inverted ? 'var(--color-downriver-400)' : 'var(--color-white)'};
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  padding: .75rem 1.5rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  
  ${props => props.$ghost && css`
    background-color: transparent;
    border: 1px solid var(${props.$inverted ? '--color-white' : '--color-downriver-400'});
    color: var(${props.$inverted ? '--color-white' : '--color-downriver-400'});
  `}

  > svg {
    margin-right: var(--space-xs);
  }
`;

export default Button;
