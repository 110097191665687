import React from 'react';
import { styled } from 'styled-components';

interface BaselineProps {
    children: React.ReactNode,
}

const Baseline = styled.div<BaselineProps>`
  > *:not(:last-child) {
    margin-bottom: var(--baseline);
  }
`;

export default Baseline;
