import React from 'react';
import { styled } from 'styled-components';

interface DropdownProps {
  children: React.ReactNode,
  $isOpen: boolean,
  $padding?: boolean,
}

const Dropdown = styled.div<DropdownProps>`
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: 1px solid var(--color-iron-400);
  box-shadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
  display: ${props => props.$isOpen ? 'block' : 'none'};
  margin-top: var(--space-md);
  max-height: 30rem;
  overflow-y: scroll;
  padding-bottom: ${props => props.$padding ? 'var(--space-lg)' : 0};
  padding-left: ${props => props.$padding ? 'var(--gutter)' : 0};
  padding-right: ${props => props.$padding ? 'var(--gutter)' : 0};
  padding-top: ${props => props.$padding ? 'var(--space-lg)' : 0};
`;

export default Dropdown;
