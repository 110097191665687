import React from 'react';
import { styled } from 'styled-components';
import { keyframes } from 'styled-components';

import interopJsLogo from '../../assets/images/interop/js.png';
import interopClogo from '../../assets/images/interop/c.png';
import interopPythonLogo from '../../assets/images/interop/python.png';
import interopRustLogo from '../../assets/images/interop/rust.png';

interface IllustrationConicProps {
  illustration: string,
}

const schieldOne = keyframes`
  0% {
    rotate: -90deg;
    opacity: 0;
  }

  100% {
    rotate: 45deg;
    opacity: 1;
  }
`;

const shieldTwo = keyframes`
  0% {
    opacity: 0;
    rotate: 180deg;
  }

  100% {
    opacity: 1;
    rotate: 45deg;
  }
`;

const shieldThree = keyframes`
  0% {
    opacity: 0;
    rotate: -90deg;
  }
  
  100% {
    opacity: 1;
    rotate: 45deg;
  }
`;

const shieldThreeAfter = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const allOne = keyframes`
  0% {
    opacity: 0;
    rotate: -135deg;
  } 
  
  66.66% {
    rotate: 90deg;
  }
  
  100% {
    opacity: 1;
    rotate: 45deg;
  }
`;

const allTwo = keyframes`
  0% {
    opacity: 0;
    rotate: 90deg;
  }

  66.66% {
    rotate: 135deg;
  }

  100% {
    rotate: 0;
    opacity: 1;
  }
`;

const allThree = keyframes`
  0% {
    opacity: 0;
    rotate: 0;
  }

  66.66% {
    rotate: -90deg;
  }

  100% {
    opacity: 1;
    rotate: 45deg;
  }
`;

const goldenTainoi400One = keyframes`
  0% {
    opacity: 0;
    rotate: 0deg;
  }

  66.66% {
    rotate: 90deg;
  }

  100% {
    opacity: 1;
    rotate: 45deg;
  }
`;

const goldenTainoi400Two = keyframes`
  0% {
    rotate: 45deg;
    opacity: 0;
  }

  66.66% {
    rotate: 0deg;
  }
  
  100% {
    rotate: 45deg;
    opacity: 1;
  }
`;

const goldenTainoi400Three = keyframes`
  0% {
    opacity: 0;
    rotate: 0;  
  }

  66.66% {
    rotate: 90deg;
  }

  100% {
    opacity: 1;
    rotate: 45deg;
  }
`;

const sunsetOrange400One = keyframes`
  0% {
    opacity: 0;
    rotate: 0;
  }

  66.66% {
    rotate: 90deg;
  }

  100% {
    rotate: 45deg;
    opacity: 1;
  }
`;

const sunsetOrange400Two = keyframes`
  0% {
    opacity: 0;
    rotate: 0;
  }

  66.66% {
    rotate: -90deg;
  }

  100% {
    rotate: -45deg;
    opacity: 1;
  }
`;

const sunsetOrange400Three = keyframes`
  0% {
    opacity: 0;
    rotate: 0;
  }

  66.66% {
    rotate: 90deg;
  }

  100% {
    rotate: 45deg;
    opacity: 1;
  }
`;

const royalBlue400One = keyframes`
  0% {
    opacity: 0;
    rotate: 0;
  }

  66.66% {
    rotate: 180deg;
  }

  100% {
    opacity: 1;
    rotate: 45deg;
  }
`;

const royalBlue400Two = keyframes`
  0% {
    opacity: 0;
    rotate: 0;
  }

  66.66% {
    rotate: -135deg;
  }

  100% {
    opacity: 1;
    rotate: -90deg;
  }
`;


const royalBlue400Three = keyframes`
  0% {
    opacity: 0;
    rotate: 0;
  }

  66.66% {
    rotate: -45deg;
  }

  100% {
    opacity: 1;
    rotate: 45deg;
  }
`;

const pendulumOne = keyframes`
  0% {
    rotate: 45deg;
  }

  50% {
    rotate: -45deg;
  }

  100% {
    rotate: 0deg;
  }
`;

const pendulumTwo = keyframes`
  0% {
    rotate: 45deg;
  }

  50% {
    rotate: -45deg;
  }

  100% {
    rotate: 0deg;
  }
`;

const interopTwo = keyframes`
  0% {
    rotate: -225deg;
  }

  50% {
    rotate: -315deg;
  }

  0% {
    rotate: -225deg;
  }
`;

const interopLogos = keyframes`
  0%, 
  33.33% {
    opacity: 1;
  }
   
   33.34%, 
   100% {
    opacity: 0;
  }  
`;

const StyledHeroConicIllustration = styled.div`
  position: relative;
  

  .gradient {
    aspect-ratio: 1;
    width: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;

    &:not(:first-child) {
      margin-top: -50%;
    }
  }

  .shield {
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    
    &:nth-child(1) {
      animation-name: ${schieldOne};
      background: conic-gradient(from 135deg at 50% 50%, transparent, var(--color-pastel-green-400));
      translate: -50%;
    }

    &:nth-child(2) {
      animation-name: ${shieldTwo};
      background: conic-gradient(from -135deg at 50% 50%, transparent, var(--color-pastel-green-400));
      margin-top: 0;
      position: absolute;
      top: 0;
      translate: 20.5%;
    }

    &:nth-child(3) {
      animation-name: ${shieldThree};
      background: conic-gradient(from 180deg at 50% 50%, var(--color-pastel-green-400), transparent);
      translate: 20.5% 20.5%;

      &::after {
        animation-duration: 1500ms;
        animation-fill-mode: forwards;
        animation-name: ${shieldThreeAfter};
        background: conic-gradient(from -45deg at 50% 50%, transparent, var(--color-pastel-green-400));
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  }

  .all {
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

    &:nth-child(1) {
      animation-name: ${allOne};
      background: conic-gradient(from 135deg at 50% 50%, transparent, var(--color-sunset-orange-400));
    }

    &:nth-child(2) {
      animation-name: ${allTwo};
      background: conic-gradient(from -90deg at 50% 50%, var(--color-golden-tainoi-400), transparent);
      translate: -50%;
    }

    &:nth-child(3) {
      animation-name: ${allThree};
      background: conic-gradient(from 45deg at 50% 50%, var(--color-royal-blue-400), transparent);
    }
  }

  .golden-tainoi-400 {
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    
    &:nth-child(1) {
      animation-name: ${goldenTainoi400One};
      background: conic-gradient(from 45deg at 50% 50%, transparent, var(--color-golden-tainoi-400));
    }

    &:nth-child(2) {
      animation-name: ${goldenTainoi400Two};
      background: conic-gradient(from 90deg at 50% 50%, var(--color-golden-tainoi-400), transparent);
      translate: -50%;
    }

    &:nth-child(3) {
      background: conic-gradient(from -45deg at 50% 50%, var(--color-golden-tainoi-400), transparent);
      animation-name: ${goldenTainoi400Three};
    }
  }

  .sunset-orange-400 {
    animation-duration: 1500ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    &:nth-child(1) {
      animation-name: ${sunsetOrange400One};
      background: conic-gradient(from 135deg at 50% 50%, transparent, var(--color-sunset-orange-400));
    }

    &:nth-child(2) {
      animation-name: ${sunsetOrange400Two};
      background: conic-gradient(from -135deg at 50% 50%, var(--color-sunset-orange-400), transparent);
      translate: -50%;
    }

    &:nth-child(3) {
      animation-name: ${sunsetOrange400Three};
      background: conic-gradient(from -135deg at 50% 50%, transparent, var(--color-sunset-orange-400));
    }
  }

  .royal-blue-400 {
    animation-duration: 1500ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    &:nth-child(1) {
      animation-name: ${royalBlue400One};
      background: conic-gradient(from 45deg at 50% 50%, transparent, var(--color-royal-blue-400));
    }

    &:nth-child(2) {
      animation-name: ${royalBlue400Two};
      background: conic-gradient(from 135deg at 50% 50%, var(--color-royal-blue-400), transparent);
      translate: -50%;
    }

    &:nth-child(3) {
      animation-name: ${royalBlue400Three};
      background: conic-gradient(from 0deg at 50% 50%, transparent, var(--color-royal-blue-400));
    }
    
  }

  .pendulum {
    aspect-ratio: 1;
    background-color: var(--color-downriver-400);
    border-radius: var(--border-radius);
    overflow: hidden;
    position: relative;

    .circle {
      animation-duration: 1500ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      border-radius: 100%;
      inset: 0;
      position: absolute;

      &:nth-child(1) {
        animation-name: ${pendulumOne};
        background-image: conic-gradient(from 180deg at 50% 75%, var(--color-pastel-green-400), var(--color-downriver-400));
        translate: 0 -50%;
      }

      &:nth-child(2) {
        animation-name: ${pendulumTwo};
        background-image: conic-gradient(from 0deg at 50% 25%, var(--color-pastel-green-400), var(--color-downriver-400));
        translate: 0 50%;
      }
    }
  }

  .interop {
    .pastel-green-400 {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        position: relative;
        width: 6rem;
        aspect-ratio: 1;
      }

      .logo:is(.rust) {
        rotate: -45deg;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999999;
      }

      .logo:not(.rust) {
        rotate: -45deg;
      }

      .loop {
        --count: 3;
        --duration: 2500ms;

        img {
          animation-duration: calc(var(--duration) * var(--count));
          animation-iteration-count: infinite;
          animation-name: ${interopLogos};
          height: 100%;
          object-fit: contain;
          object-position: center;
          position: absolute;
          width: 100%;
        }

        img:nth-child(2) {
          animation-delay: calc(var(--duration) * -2);
        }

        img:nth-child(3) {
          animation-delay: calc(var(--duration) * -1);
        }
      }
      
      &:nth-child(1) {
        background: conic-gradient(from 90deg at 50% 50%, var(--color-pastel-green-400), transparent);
        position: relative;
        rotate: 45deg;
        translate: -50%;
        z-index: 1;
      }

      &:nth-child(2) {
        animation-name: ${interopTwo};
        animation-duration: 5000ms;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        background: conic-gradient(from -180deg at 50% 50%, var(--color-pastel-green-400), transparent);
        margin-top: 0;
        position: absolute;
        top: 0;
        translate: 20.5%;
        rotate: -225deg;
      }
    }
  }
`;

export default function IllustrationConic({ illustration }: IllustrationConicProps): JSX.Element {
  return (
    <StyledHeroConicIllustration>
      {illustration === 'pastel-green-400' && (
        <>
          <div className="gradient shield"></div>
          <div className="gradient shield"></div>
          <div className="gradient shield"></div>
        </>
      )}
      {illustration === 'all' && (
        <>
          <div className="gradient all"></div>
          <div className="gradient all"></div>
          <div className="gradient all"></div>
        </>
      )}
      {illustration === 'golden-tainoi-400' && (
        <>
          <div className="gradient golden-tainoi-400"></div>
          <div className="gradient golden-tainoi-400"></div>
          <div className="gradient golden-tainoi-400"></div>
        </>
      )}
      {illustration === 'sunset-orange-400' && (
        <>
          <div className="gradient sunset-orange-400"></div>
          <div className="gradient sunset-orange-400"></div>
          <div className="gradient sunset-orange-400"></div>
        </>              
      )}
      {illustration === 'royal-blue-400' && (
        <>
          <div className="gradient royal-blue-400"></div>
          <div className="gradient royal-blue-400"></div>
          <div className="gradient royal-blue-400"></div>
        </>
      )}
      {illustration === 'pendulum' && (
        <div className="pendulum">
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      )}
      {illustration === 'interop' && (
        <div className="interop">
          <div className="gradient pastel-green-400">
            <div className="logo">
              <div className="loop">
                <img src={interopJsLogo} />
                <img src={interopClogo} />
                <img src={interopPythonLogo} />
              </div>
            </div>
          </div>
          <div className="gradient pastel-green-400">
            <div className="logo rust">
              <img src={interopRustLogo} />
            </div>
          </div>
        </div>
      )}
    </StyledHeroConicIllustration>
  );
}
