import React from 'react';
import { Helmet } from 'react-helmet';
import { useRoute } from 'react-router5';
import { Seo } from './Seo';
import { isBrowser } from '../../util';

const image = '/images/logo-1024.png';
const icon = '/images/logo-512.png';
export const canonicalDomain = 'https://tweedegolf.nl';

export function DefaultSeo() {
  const { route } = useRoute();
  const title = 'Tweede golf';
  const description = '';
  const prefix = isBrowser ? '' : canonicalDomain;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon shortcut" href={`${prefix}${icon}`} />
        {route && <link rel="canonical" href={`${canonicalDomain}${route.path}`} />}
        {route && <meta property="og:url" content={`${canonicalDomain}${route.path}`} />}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@tweedegolfbv" />
      </Helmet>
      <Seo title={title} description={description} image={`${prefix}${image}`} />
    </>
  );
}
