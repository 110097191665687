import { css } from 'styled-components';

export const container = css`
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container-width);
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  width: 100%;
`;

export const line = (axis:string, color?: string, rightAlign?: boolean) => css`
  ${axis == 'x' && css`
    &::after {
      background-color: var(--color-${color || 'pastel-green-400'});
      content: '';
      display: block;
      height: 1px;
      margin-top: var(--baseline);
      width: 6.75rem;
      margin-left: ${rightAlign ? 'auto' : 0};
      margin-right: ${rightAlign ? 0 : 'auto'};
    }
  `}

  ${axis == 'y' && css`
    position: relative;
    
    &::before {
      background-color: var(--color-${color || 'downriver-400'});
      content: '';
      display: block;
      height: 1.5em;
      left: calc(-1 * var(--gutter));
      position: absolute;
      top: 0;
      width: 1px;
      z-index: 1;
    }

    &:is(h1, h2, h3, h4, h5, h6)::before {
      top: 50%;
      translate: 0 -50%;
    }
  `}
`;
