import { AnimationInterface } from '../types';

class Star {
  x: number;
  y: number;
  z: number;

  constructor(x: number, y: number, z: number) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  update(focalLength: number) {
    this.z -= 1;

    if (this.z <= 0) {
      this.z = focalLength;
    }
  }

  draw(ctx: CanvasRenderingContext2D, focalLength: number, centerX: number, centerY: number) {
    ctx.beginPath();
    ctx.arc(
      (this.x - centerX) * (focalLength / this.z) + centerX,
      (this.y - centerY) * (focalLength / this.z) + centerY,
      (focalLength / this.z) * 0.35,
      0,
      2 * Math.PI,
    );
    ctx.fill();
  }
}

export default class StarAnimation implements AnimationInterface {

  background: string;
  color: string;
  focalLength!: number;
  stars: Star[] = [];
  canvas!: HTMLCanvasElement;

  constructor(background?: string, color?: string) {
    this.background = background || '#0A2540';
    this.color = color || '#48E070';
  }

  init = (canvas: HTMLCanvasElement) => {
    this.canvas = canvas;
    const numStars = (canvas.width + canvas.height) / 4;
    for (let i = 0; i < numStars; i += 1) {
      this.stars.push(new Star(
        Math.random() * canvas.width,
        Math.random() * canvas.height,
        Math.random() * canvas.width,
      ));
    }
  }

  update(ctx: CanvasRenderingContext2D) {
    const centerX: number = this.canvas.width / 2;
    const centerY: number = this.canvas.height / 2;

    // ctx.fillStyle = this.background;
    // ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.fillStyle = this.color;
    this.stars.forEach((star) => {
      star.update(this.focalLength);
      star.draw(ctx, this.focalLength, centerX, centerY);
    });
  }

  move(x: number, y: number) {
    this.focalLength = 300 + (y / window.innerHeight) * 600;
  }
}
