import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';

const StyledScrollProgress = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  .progress {
    background-color: var(--color-pastel-green-400);
    height: .25rem;
  }
`;

export default function ScrollProgress(): JSX.Element {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    let running = false;
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const updateScroll = () => {
      if (!running) {
        running = true;
        timeout = setTimeout(() => {
          setWidth((window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100);
          running = false;
        }, 20);
      }
    }
        
    window.addEventListener('scroll', updateScroll, false);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener('scroll', updateScroll)
    }
  }, []);

  return (
    <StyledScrollProgress>
      <div className="progress" style={{ maxWidth: `${width}%` }}></div>
    </StyledScrollProgress>
  )
}
