import React from 'react';
import { styled } from 'styled-components';
import VectorAnimation from '../animations/VectorAnimation';
import useAwesome from '../hooks/useAwesome';

interface CanvasVectorProps {
  background?: string;
  color?: string;
  intensity?: number;
}

const StyledCanvasVector = styled.canvas`
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default function CanvasVector({ background, color, intensity }: CanvasVectorProps) {
  const element = useAwesome(new VectorAnimation(background, color, intensity));

  return (
    <StyledCanvasVector className="awesome" ref={element} />
  );
}
