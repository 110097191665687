import React, { ReactNode } from 'react';
import { styled } from 'styled-components';
import Link from './Link';
import { breakpoints } from '../styled/utils';

interface PersonProps {
  notEmployed?: boolean,
  email?: string,
  image?: string,
  name: string,
  position?: string | ReactNode,
  inverted?: boolean,
  userId?: number,
}

const StyledPerson = styled.div`
  align-items: center;
  display: flex;
  column-gap: var(--space-sm);
  line-height: var(--line-height-tight);

  .name {
    font-weight: 500;
  }
`;

interface ImageProps {
  $image: string,
}

const Image = styled.div<ImageProps>`
  aspect-ratio: 1;
  background-color: var(--color-iron-300);
  background-image: url(${props => props.$image});
  background-size: cover;
  border-radius: var(--border-radius);
  flex-shrink: 0;
  position: relative;
  width: 4.5rem;

  @media ${breakpoints.md} {
    width: 5rem;    
  }

  > a {
    inset: 0;
    position: absolute;
  }
`

export default function Person({email, image, name, position, userId }: PersonProps): JSX.Element {
  const userName = name.toLowerCase();

  return (
    <div>
      <StyledPerson>
        {image && userId ? (
          <Image $image={image}>
            <Link routeName="about.author" routeParams={{ id: userId, name: userName }} title={name} />
          </Image>
        ) : (image && <Image $image={image} />)}
        <div>
          {userId ? (
            <Link className="name" routeName="about.author" routeParams={{ id: userId, name: userName }}>{name}</Link>
          ) : (
            <div className="name">{name}</div>
          )}
          {position && <div className="position">{position}</div>}
          {email && <a className="email" href={'mailto:' + email}>{email}</a>}
        </div>
      </StyledPerson>
    </div>
  );
}
