import React from 'react';
import { css, styled } from 'styled-components';
import { breakpoints } from '../../styled/utils';
import { BreakpointKey, Breakpoints } from '../../types';

export interface ColProps {
  children?: React.ReactNode,
  $col?: Breakpoints,
  $padding?: string,
  $row?: Breakpoints,
  $zIndex?: number,
  $align?: Breakpoints,
  $display?: Breakpoints,
}

const Col = styled.div<ColProps>`
  padding-left: ${props => props.$padding ? `var(--${props.$padding})` : '0'};

  ${props => props.$display && Object.entries(props.$display).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      display: ${value};
    }
  `)}

  ${props => props.$col && Object.entries(props.$col).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      grid-column: ${value};
    }
  `)}

  ${props => props.$row && Object.entries(props.$row).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      grid-row: ${value};
    }
  `)}

  ${props => props.$align && Object.entries(props.$align).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      align-self: ${value};
    }
  `)}

  ${props => props.$zIndex && css`
    position: relative;
    z-index: ${props.$zIndex};
  `}
`;

export default Col;
