import { createAppRouter } from './router';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router5';
import App from './components/App';
import browserPlugin from 'router5-plugin-browser';
import React from 'react';

const router = createAppRouter();
router.usePlugin(browserPlugin());

const container = document.getElementById('root') as HTMLDivElement;

router.start(() => {
  if (NO_HIDRATE) {
    createRoot(container).render( (
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    ));
  } else {
    hydrateRoot(container, (
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    ));
  }
});
