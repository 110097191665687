import React from 'react';
import { css, styled } from 'styled-components';
import { breakpoints } from '../../styled/utils';
import { BreakpointKey, Breakpoints } from '../../types';

interface GridProps {
  $align?: Breakpoints,
  children: React.ReactNode,
  $columnGap?: string,
  $cols?: Breakpoints,
  $rowGap?: string,
}

const Grid = styled.div<GridProps>`
  column-gap: ${props => props.$columnGap ? `var(--${props.$columnGap})` : '0'};
  display: grid;
  row-gap: ${props => props.$rowGap ? `var(--${props.$rowGap})` : '0'};

  ${props => props.$cols && Object.entries(props.$cols).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      grid-template-columns: repeat(${value}, 1fr);
    }
  `)}

  ${props => props.$align && Object.entries(props.$align).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      align-items: ${value};
    }
  `)}
`;

export default Grid; 
