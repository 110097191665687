import { useEffect, useRef } from 'react';
import { AnimationInterface } from '../types';
import { isBrowser } from '../util';

export default function useAwesome(animation: AnimationInterface) {
  const element = useRef(null);

  useEffect(() => {
    const canvas = element.current as HTMLCanvasElement | null;

    if (!canvas || !isBrowser) {
      return;
    }

    const resize = () => {
      canvas.width = canvas.offsetWidth * window.devicePixelRatio;
      canvas.height = canvas.offsetHeight * window.devicePixelRatio;
    };

    resize();

    animation.init(canvas);

    let running = true;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    const executeFrame = () => {
      if (running) {
        window.requestAnimationFrame(executeFrame);
        if (canvas.width !== canvas.offsetWidth) {
          resize();
        }
        animation.update(ctx);
      }
    };

    const onMove = (e: MouseEvent | TouchEvent) => {
      const bb = canvas.getBoundingClientRect();
      if (e instanceof TouchEvent && e.touches) {
        animation.move(
          e.touches[0].clientX * window.devicePixelRatio + bb.left,
          e.touches[0].clientY * window.devicePixelRatio + bb.top - window.scrollY,
        );
      } else if(e instanceof MouseEvent) {
        animation.move(
          e.clientX * window.devicePixelRatio - bb.left,
          e.clientY * window.devicePixelRatio - bb.top - window.scrollY,
        );
      }
    };

    window.addEventListener('mousemove', onMove, false);
    window.addEventListener('touchmove', onMove, false);

    executeFrame();

    return () => {
      running = false;
      window.removeEventListener('mousemove', onMove, false);
      window.removeEventListener('touchmove', onMove, false);
    };
  }, [element.current]);

  return element;
}
