import React from 'react';
import Dropdown from '../Dropdown';
import Nav from './Nav';

interface HamburgerNavProps {
  isOpen: boolean,
}

export default function HamburgerNav({ isOpen }: HamburgerNavProps): JSX.Element {
  return (
    <Dropdown $padding $isOpen={isOpen}>
      <Nav direction="vertical" />
    </Dropdown>
  );
}