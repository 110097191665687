import React from 'react';
import { styled } from 'styled-components';
import { container } from '../../styled/mixins'

export interface ContainerProps {
  children?: React.ReactNode,
  $position?: string,
  $height?: string,
}

const Container = styled.div<ContainerProps>`
  ${container}
  height: ${props => props.$height ? props.$height : 'auto'};
  position: ${props => props.$position ? props.$position : 'static'};
`;

export default Container;
