import React from 'react';
import { styled } from 'styled-components';

interface CardProps {
  children: React.ReactNode,
  dark?: boolean,
}

interface CardFooterProps {
  children: React.ReactNode,
  dark?: boolean,
}

export const CardFooter = styled.div<CardFooterProps>`
  align-items: center;
  border-top: 1px solid ${props => props.dark ? 'var(--color-downriver-300)' : 'var(--color-iron-300)'};
  column-gap: var(--gutter);
  display: flex;
  justify-content: space-between;
  padding-top: var(--baseline);
  row-gap: var(--baseline);

  > a {
    white-space: nowrap;
  }
`;

const Card = styled.div<CardProps>`
  align-items: stretch;
  background-color: ${props => props.dark ? 'var(--color-downriver-400)' : 'var(--color-white)'};
  border-radius: var(--border-radius);
  border: 1px solid ${props => props.dark ? 'var(--color-downriver-300)' : 'var(--color-iron-400)'};
  color: ${props => props.dark ? 'var(--color-white)' : 'var(--color-downriver-400)'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--space-lg) var(--gutter);
  row-gap: var(--baseline);
  scroll-margin-top: calc(var(--space-lg) + 1.5 * var(--baseline));

  a code {
    font-size: 0.85rem;
  }

  a {
    text-decoration: none;
  }
`;

export default Card;
