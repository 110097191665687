import React from 'react';
import { css, styled } from 'styled-components';
import { line } from '../styled/mixins';

interface ParagraphProps {
  children: React.ReactNode,
  $color?: string,
  $line?: string,
  $lineColor?: string,
  $mono?: boolean,
  $size?: string,
  $marginTop?: number,
  $maxCharacters?: number,
}

const Paragraph = styled.div<ParagraphProps>`
  ${props => props.$marginTop && css`
    margin-top: ${props.$marginTop}rem;
  `}

  ${props => props.$size && css`
    font-size: var(--font-size-${props.$size});
  `}

  ${props => props.$mono && css`
    font-family: var(--font-family-mono);
  `}

  ${props => props.$line && css`
    ${line(props.$line, props.$lineColor || 'downriver-400')}
  `}

  ${props => props.$maxCharacters && css`
    max-width: ${props.$maxCharacters}ch;
  `}
`;

export default Paragraph;
