import React from 'react';
import { css, styled } from 'styled-components';
import { breakpoints } from '../../styled/utils';
import { BreakpointKey, Breakpoints } from '../../types';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Icon, { IconProps } from '../Icon';

interface HamburgerProps {
  display?: Breakpoints,
  isOpen: boolean,
  onClick: () => void,
}

interface StyledHamburgerProps extends IconProps { 
  $display?: Breakpoints,
}

const StyledHamburger = styled(Icon)<StyledHamburgerProps>`
  cursor: pointer;
  font-size: var(--font-size-lg);

  ${props => props.$display && Object.entries(props.$display).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      display: ${value};
    }
  `)}
`;

export default function Hamburger({ onClick, isOpen, display }: HamburgerProps): JSX.Element {
  return (
    <StyledHamburger onClick={onClick} $display={display} icon={isOpen ? faTimes : faBars} />
  );
}
