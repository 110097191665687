import React from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title: string,
  description?: string,
  image?: string | null,
  children?: React.ReactNode,
}

export function Seo({ title, image, description, children }: SeoProps) {
  
  const truncatedDescription = description && description.length > 196 ? description.substring(0, 196) + ' ...' : description;
  
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" property="og:title" content={title} />
      {truncatedDescription && <meta name="description" property="og:description" content={truncatedDescription} />}
      {image && <meta name="image" property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {children}
    </Helmet>
  );
}
