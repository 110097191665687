import React from 'react';
import { css, styled } from 'styled-components';
import Flex from './layout/Flex';
import Baseline from './layout/Baseline';
import Col from './layout/Col';
import Container from './layout/Container';
import Grid from './layout/Grid';
import { breakpoints } from '../styled/utils';
import StyledHeroConicIllustration from './HeroConicIllustration';

interface HeroProps {
  children: React.ReactNode;
  illustration: string;
  $stripe?: boolean;
}

interface HeroFlexProps {
  $stripe?: boolean;
}

const HeroFlex = styled(Flex) <HeroFlexProps>`
  overflow: hidden;
  padding-bottom: var(--space-xxl);
  padding-top: var(--space-xxl);

  @media ${breakpoints.md} {
    min-height: calc(100svh - 132px);
    position: relative;
  }

  ${(props) =>
    props.theme.color &&
    css`
      overflow: hidden;
      position: relative;

      &::after {
        background: conic-gradient(
          from 90deg at 50% 50%,
          var(--color-${props.theme.color}),
          var(--color-white)
        );
        content: '';
        filter: blur(146px) brightness(138%) opacity(40%);
        position: absolute;
        inset: 0;
        z-index: -1;
      }
    `}

  ${(props) =>
    props.$stripe &&
    css`
      position: relative;

      &::before {
        background-color: var(--color-${props.theme.color});
        border-top-left-radius: var(--border-radius);
        border-top-right-radius: var(--border-radius);
        bottom: 0;
        content: '';
        height: var(--space-sm);
        left: 50%;
        position: absolute;
        translate: -50%;
        width: calc(var(--container-width) - var(--container-padding) * 2);
      }
    `}
`;

interface IllustrationProps {
  $illustration?: string;
}

const Illustration = styled(Col) <IllustrationProps>`
  opacity: 0.25;

  @media ${breakpoints.lg} {
    opacity: 1;
    top: ${(props) =>
    props.$illustration == 'pastel-green-400' ? '-3rem' : 'a'};
    width: 17.5%;
    right: var(--container-padding);
    position: absolute;
  }
`;

export default function Hero({
  children,
  illustration,
  $stripe,
}: HeroProps): JSX.Element {
  return (
    <HeroFlex $stripe={$stripe} $align={{ sm: 'center' }}>
      <Container $position="relative">
        <Grid
          $cols={{ sm: 4, md: 8, lg: 12 }}
          $columnGap="gutter"
          $align={{ sm: 'center' }}
        >
          <Col
            $col={{ sm: '1 / span 4', md: '1 / span 6' }}
            $row={{ sm: '1 / 2' }}
            $zIndex={1}
          >
            <Baseline>{children}</Baseline>
          </Col>
          <Illustration
            $col={{
              sm: '2 / span 2',
              md: '5 / span 4',
              lg: '9 / span 3',
              xl: '10 / span 3',
            }}
            $row={{ sm: '1 / 2' }}
            $illustration={illustration}
          >
            <StyledHeroConicIllustration illustration={illustration} />
          </Illustration>
        </Grid>
      </Container>
    </HeroFlex>
  );
}
