import { Article } from './types.ts';

export const formatDate = (date: Date | string) => {
  const d = new Date(date);
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export const explicitArticleOrder = (articles: Article[], ids: number[]): Article[] => {
  return articles.reduce((sorted, article) => {
    const new_index = ids?.indexOf(article.id);
    if (new_index !== undefined && new_index !== -1) {
      sorted[new_index] = article;
    }
    return sorted
  }, [] as Article[])
}

export const isBrowser = typeof window !== 'undefined';

export const isProduction = isBrowser && window.location.host === 'tweedegolf.nl';

export const apiUrl = isBrowser ? '' : process.env.API_URL;