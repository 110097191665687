import React from 'react';
import { styled } from 'styled-components';
import StarAnimation from '../animations/StarAnimation';
import useAwesome from '../hooks/useAwesome';

interface CanvasStarProps {
  background?: string;
  color?: string;
}

const StyledCanvasStar = styled.canvas`
  background-size: cover;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;

export default function CanvasStar({ background, color }: CanvasStarProps) {
  const element = useAwesome(new StarAnimation(background, color));

  return (
    <StyledCanvasStar className="awesome" ref={element} />
  );
}
