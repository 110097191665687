import React, { ReactElement, useRef } from 'react';
import { Author, Article, User } from '../../../types';
import { formatDate } from '../../../util';
import Card, { CardFooter } from '../../Card';
import Heading from '../../Heading';
import Link from '../../Link';
import Person from '../../Person';
import Tags from '../../Tags';
import Text from '../../Text';
import Sections from '../../layout/Sections';
import Truncate from '../../Truncate';

interface BlogCardProps {
  authors?: Author[] | User[],
  article: Article,
  position?: number,
  summary?: string,
}

export default function BlogCard({ article, summary, authors }: BlogCardProps): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Card ref={ref} id={`${article.id}`}>
      <Sections $rowGap="baseline">
        <div>
          <Heading as="strong" $color="pastel-green-400">{formatDate(article.date)}</Heading>
          <Heading $line="x" as="h3"><Link routeName="blog.view" routeParams={{ slug: article.slug, id: article.id }}>{article.title}</Link></Heading>
        </div>
        {summary && (
          <Truncate $lines={3}>
            <Text markdown en={summary} />
          </Truncate>
        )}
        {authors && (
          <Sections $rowGap="space-sm">
            {authors.map((author, idx) =>
              <Person
                key={idx}
                name={author.name}
                image={author.image}
                userId={'id' in author ? author.id : undefined}
                position={<Text en={author.position} />}
              />
            )}
          </Sections>
        )}
      </Sections>
      <CardFooter>
        <Tags tags={article.tags} />
        <Link className="medium" routeName="blog.view" routeParams={{ slug: article.slug, id: article.id }}><Text en="Read article" /></Link>
      </CardFooter>
    </Card>
  );
}
