import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { fontFace, body, root, typography, styles, highlight } from '../../styled/GlobalStyle';
import Background from './Background';
import Footer from './Footer';
import Header from './Header';
import reset from 'styled-reset'
import { css, styled } from 'styled-components';
import { DefaultSeo } from '../seo/DefaultSeo';

interface LayoutProps {
  children: React.ReactNode,
  hero?: boolean,
  paddingBottom?: string;
}

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${root}
  ${fontFace}
  ${body}
  ${typography}
  ${styles}
  ${highlight}
`;

interface MainProps {
  $paddingBottom?: string,
  $navOpen?: boolean,
  $hero?: boolean,
}

const Main = styled.main<MainProps>`
  padding-bottom: ${(props) => props.$paddingBottom ? props.$paddingBottom : 'var(--space-xxl)'};
  position: relative;

  &::after {
    background-color: var(--color-downriver-400);
    content: '';
    height: 100%;
    left: 0;
    opacity: ${props => props.$navOpen ? '.75' : '0'};
    pointer-events: none;
    position: fixed;
    top: 0;
    transition-duration: var(--transition-duration);
    transition-property: opacity;
    transition-timing-function: var(--transition-timing-function);
    width: 100%;
    z-index: 5;
  }

  ${props => !props.$hero && css`
    padding-top: var(--space-xxl);
  `}
`;

export default function Layout({ children, hero, paddingBottom }: LayoutProps): JSX.Element {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <>
      <GlobalStyle />
      <DefaultSeo />
      <Header navOpen={navOpen} setNavOpen={setNavOpen} />
      <Main $hero={hero} $navOpen={navOpen} $paddingBottom={paddingBottom} >
        <Background $withContainer $cols={{ sm: 4, md: 8, lg: 12 }} />
        {children}
      </Main>
      <Footer />
    </>
  );
}
