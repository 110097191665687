import React from 'react';
import { css, styled } from 'styled-components';
import { line } from '../styled/mixins';
import { breakpoints } from '../styled/utils';

interface ListProps {
  $baseline?: string,
  $border?: boolean,
  $bullets?: boolean,
  children: React.ReactNode,
  $counter?: string,
  $line?: string,
  $lineColor?: string,
  $size?: string,
  $horizontal?: boolean,
  $vertical?: boolean,
}

const List = styled.ul<ListProps>`
  list-style-position: inside;
  list-style-type: ${props => props.$bullets ? 'circle' : 'none'};

  ${props => props.$counter && css`
    counter-reset: ${props.$counter};
  `}

  ${props => props.$horizontal && css`
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
  `}

  li {
    ${props => props.$baseline && css`
      &:not(:last-child) {
        margin-bottom: ${`var(--baseline-${props.$baseline})`};
      }
    `}

    ${props => props.$bullets && css`
      &::marker {
        color: ${props => props.theme.color ? `var(--color-${props.theme.color})` : 'var(--color-pastel-green-400)'};
      }
    `}

    ${props => props.$size && css`
      font-size: var(--font-size-${props.$size});
    `}

    ${props => props.$line == 'y' && css`
      @media ${breakpoints.md} {
        ${line(props.$line, props.$lineColor)}
      }
    `}

    ${props => props.$border && css`
      padding-top: var(--space-xs);

      @media ${breakpoints.md} {
        padding-left: var(--gutter);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-iron-400);
        padding-bottom: var(--space-xs);
      }
    `}

    &.active {
      color: var(--color-pastel-green-400);
      font-weight: 500;
    }
  }
`;

export default List;
