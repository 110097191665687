import { AnimationInterface } from '../types';

class Dot {
  x: number;
  y: number;
  vx: number;
  vy: number;
  r: number;
  offset: number;

  constructor(x: number, y: number, vx: number, vy: number, r: number, offset = 0) {
    this.x = x;
    this.y = y;
    this.vx = vx;
    this.vy = vy;
    this.r = r;
    this.offset = offset;
  }

  update(canvas: HTMLCanvasElement) {
    if (this.y < -this.offset || this.y > canvas.height + this.offset) {
      this.vy = - this.vy;
    } else if (this.x < -this.offset || this.x > canvas.width + this.offset) {
      this.vx = - this.vx;
    }

    this.x += this.vx;
    this.y += this.vy;
  }

  draw(ctx: CanvasRenderingContext2D, color: string) {
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.arc(this.x, this.y, this.r, 0, 2 * Math.PI, false);
    ctx.fill();
  }
}

export default class VectorAnimation implements AnimationInterface {

  background: string;
  color: string;
  intensity: number;

  x!: number;
  y!: number;

  dotDist!: number;
  cursorDist!: number;

  dots: Dot[] = [];
  canvas!: HTMLCanvasElement;

  constructor(background?: string, color?: string, intensity?: number) {
    this.background = background || '#0A2540';
    this.color = color || '#48E070';
    this.intensity = intensity || 1;
  }

  init = (canvas: HTMLCanvasElement) => {
    this.canvas = canvas;
    this.dotDist = 50;
    this.cursorDist = 120;

    if (canvas.width < 992) {
      this.dotDist = 40;
      this.cursorDist = 80;
    }

    this.x = canvas.width / 2;
    this.y = canvas.height / 2;

    const numDots: number = (canvas.width + canvas.height) / 6;
    for (let i = 0; i < numDots; i += 1) {
      this.dots.push(new Dot(
        Math.random() * canvas.width,
        Math.random() * canvas.height,
        (Math.random() - 0.5) * 0.5,
        (Math.random() - 0.5) * 0.5,
        Math.random() * 1.5,
      ));
    }
  }

  update(ctx: CanvasRenderingContext2D) {
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.fillStyle = this.color;
    ctx.strokeStyle = this.color;
    ctx.globalAlpha = 0.5;

    for (let i = 0; i < this.dots.length; i += 1) {
      const dit = this.dots[i];
      dit.update(this.canvas);
      dit.draw(ctx, this.color);

      if (
        Math.abs(dit.y - this.y) < this.cursorDist / 2 * this.intensity &&
        Math.abs(dit.x - this.x) < this.cursorDist / 2 * this.intensity
      ) {
        ctx.beginPath();
        ctx.lineWidth = dit.r / 2;
        ctx.moveTo(dit.x, dit.y);
        ctx.lineTo(this.x, this.y);
        ctx.stroke();
      }

      for (let j = i + 1; j < this.dots.length; j += 1) {
        const dat = this.dots[j];
        if (
          Math.abs(dit.x - dat.x) < this.dotDist * this.intensity &&
          Math.abs(dit.y - dat.y) < this.dotDist * this.intensity &&
          Math.abs(dit.y - this.y) < this.cursorDist * this.intensity &&
          Math.abs(dit.x - this.x) < this.cursorDist * this.intensity
        ) {
          ctx.beginPath();
          ctx.lineWidth = ((dit.r + dat.r) / 4) / (this.dotDist * 0.1);
          ctx.moveTo(dit.x, dit.y);
          ctx.lineTo(dat.x, dat.y);
          ctx.stroke();
        }
      }
    }
  }

  move(x: number, y: number) {
    this.x = x;
    this.y = y + window.scrollY;
  }
}
