import { Client } from '../types';
import albelli from '../../assets/images/client-albelli.png';
import isrg from '../../assets/images/client-isrg.jpg';
import lightyear from '../../assets/images/client-lightyear.png';
import nlnet from '../../assets/images/client-nlnet.png';
import rustfoundation from '../../assets/images/client-rustfoundation.png';
import glanum from '../../assets/images/client-glanum.jpg';
import soil_heroes from '../../assets/images/client-soil-heroes.jpg';
import ihub from '../../assets/images/client-ihub.png';
import huverba from '../../assets/images/client-huverba.jpg';
import pect from '../../assets/images/client-pect.jpg';
import olifantenpaadje from '../../assets/images/client-olifantenpaadje.jpg';
import healthvalley from '../../assets/images/client-healthvalley.jpg';
import highTechNL from '../../assets/images/client-highTechNL.png';
import scailable from '../../assets/images/client-scailable.png';
import sodaq from '../../assets/images/client-sodaq.png';
import gemeente_amsterdam from '../../assets/images/client-gemeente-amsterdam.jpg';
import vws from '../../assets/images/client-vws.jpg';
import privacy_by_design from '../../assets/images/client-privacy-by-design.jpg';
import sidn from '../../assets/images/client-sidn.png';
import dutch_iot from '../../assets/images/client-dutch-iot.jpg';
import technolution from '../../assets/images/client-technolution.png';
import ferrous from '../../assets/images/client-ferrous.png';
import nlnetlabs from '../../assets/images/client-nlnetlabs.png';
import gama_space from '../../assets/images/client-gama-space.png';
import sioux from '../../assets/images/client-sioux.png';
import nedap from '../../assets/images/client-nedap.png';
import nlr from '../../assets/images/client-nlr.png';
import stf from '../../assets/images/client-stf.png';
import ictu from '../../assets/images/client-ictu.png';
import twtg from '../../assets/images/client-twtg.png';
import tomtom from '../../assets/images/client-tomtom.png';
import bluebird from '../../assets/images/client-bluebird.png';
import verderhelpen from '../../assets/images/client-verderhelpen.png';
import donders from '../../assets/images/client-donders-institute.png';
import rivm from '../../assets/images/client-rivm.png';
import askja from '../../assets/images/client-askja-reizen-dark.png';
import microsoft from '../../assets/images/client-microsoft.png';
import tno from '../../assets/images/client-tno.png';

const allClients = [
  {
    name: 'Albelli',
    tag: 'albelli',
    description: 'Client',
    image: albelli,
    link: 'https://www.albelli.nl/'
  },
  {
    name: 'Internet Security Research Group',
    tag: 'isrg',
    description: 'Client',
    image: isrg,
    link: 'https://www.abetterinternet.org/'
  },
  {
    name: 'Lightyear',
    tag: 'ly',
    description: 'Client',
    image: lightyear,
    link: 'https://lightyear.one/'
  },
  {
    name: 'NLnet Foundation',
    tag: 'nlnet',
    description: 'Supporter',
    image: nlnet,
    link: 'https://nlnet.nl/'
  },
  {
    name: 'Rust Foundation',
    tag: 'rustfoundation',
    description: 'Silver Member',
    image: rustfoundation,
    link: 'https://foundation.rust-lang.org/'
  },
  {
    name: 'Glanum Medical Devices',
    tag: 'glanum',
    description: 'Client',
    image: glanum,
    link: 'https://www.glanum-diagnostics.com/'
  },
  {
    name: 'Soil Heroes',
    tag: 'sh',
    description: 'Client',
    image: soil_heroes,
    link: 'https://www.soilheroesfoundation.com/'
  },
  {
    name: 'iHub',
    tag: 'ihub',
    description: 'Partner',
    image: ihub,
    link: 'https://ihub.ru.nl/'
  },
  {
    name: 'Huverba',
    tag: 'huverba',
    description: 'Client',
    image: huverba,
    link: 'https://www.huverba.nl/'
  },
  {
    name: 'Pect',
    tag: 'pect',
    description: 'Client',
    image: pect,
    link: 'https://pect.nl/'
  },
  {
    name: 'Olifantenpaadje',
    tag: 'op',
    description: 'Client',
    image: olifantenpaadje,
    link: 'https://olifantenpaadje.nl/'
  },
  {
    name: 'Health Valley',
    tag: 'healthvalley',
    description: 'Partner',
    image: healthvalley,
    link: 'https://www.healthvalley.nl/'
  },
  {
    name: 'High Tech NL',
    tag: 'hightechnl',
    description: 'Member',
    image: highTechNL,
    link: 'https://www.hightechnl.nl/'
  },
  {
    name: 'Scailable',
    tag: 'scailable',
    description: 'Client',
    image: scailable,
    link: 'https://scailable.net/'
  },
  {
    name: 'Sodaq',
    tag: 'sodaq',
    description: 'Client',
    image: sodaq,
    link: 'https://sodaq.com/'
  },
  {
    name: 'Gemeente Amsterdam',
    tag: 'amsterdam',
    description: 'Client',
    image: gemeente_amsterdam,
    link: 'https://www.amsterdam.nl/'
  },
  {
    name: 'Ministerie van VWS',
    tag: 'vws',
    description: 'Client',
    image: vws,
    link: 'https://www.rijksoverheid.nl/ministeries/ministerie-van-volksgezondheid-welzijn-en-sport'
  },
  {
    name: 'Privacy by Design Foundation',
    tag: 'pbdf',
    description: 'Preferred partner',
    image: privacy_by_design,
    link: 'https://privacybydesign.foundation/index.html'
  },
  {
    name: 'SIDN',
    tag: 'sidn',
    description: 'Innovation partner',
    image: sidn,
    link: 'https://www.sidn.nl/'
  },
  {
    name: 'Dutch IoT Solutions',
    tag: 'dis',
    description: 'Partner',
    image: dutch_iot,
    link: 'https://dutch-iot.com/'
  },
  {
    name: 'Technolution',
    tag: 'technolution',
    description: 'Client',
    image: technolution,
    link: 'https://www.technolution.com/'
  },
  {
    name: 'Ferrous Systems',
    tag: 'ferrous',
    description: 'Partner',
    image: ferrous,
    link: 'https://ferrous-systems.com/'
  },
  {
    name: 'NLnet Labs',
    tag: 'nlnetlabs',
    description: 'Partner',
    image: nlnetlabs,
    link: 'https://nlnetlabs.nl/'
  },
  {
    name: 'GAMA',
    tag: 'gama',
    description: 'Client',
    image: gama_space,
    link: 'https://www.gamaspace.com/'
  },
  {
    name: 'Sioux',
    tag: 'sioux',
    description: 'Client',
    image: sioux,
    link: 'https://www.sioux.eu/'
  },
  {
    name: 'Nedap',
    tag: 'nedap',
    description: 'Client',
    image: nedap,
    link: 'https://nedap.com/'
  },
  {
    name: 'NLR',
    tag: 'nlr',
    description: 'Client',
    image: nlr,
    link: 'https://www.nlr.nl/'
  },
  {
    name: 'Sovereign Tech Fund',
    tag: 'stf',
    description: 'Supporter',
    image: stf,
    link: 'https://www.sovereigntechfund.de/'
  },
  {
    name: 'ICTU',
    tag: 'ictu',
    description: 'Client',
    image: ictu,
    link: 'https://www.ictu.nl/'
  },
  {
    name: 'TWTG',
    tag: 'twtg',
    description: 'Client',
    image: twtg,
    link: 'https://www.twtg.io/'
  },
  {
    name: 'TomTom',
    tag: 'tomtom',
    description: 'Client',
    image: tomtom,
    link: 'https://www.tomtom.com/'
  },
  {
    name: 'BlueBird Power',
    tag: 'bluebird',
    description: 'Client',
    image: bluebird,
    link: 'https://bluebirdpower.com/'
  },
  {
    name: 'Verder Helpen',
    tag: 'vh',
    description: 'Client',
    image: verderhelpen,
    link: 'https://verderhelpen.nl/'
  },
  {
    name: 'Donders Institute for Brain, Cognition, and Behaviour',
    tag: 'donders',
    description: 'Client',
    image: donders,
    link: 'https://www.ru.nl/en/donders-institute'
  },
  {
    name: 'RIVM',
    tag: 'rivm',
    description: 'Client',
    image: rivm,
    link: 'https://www.rivm.nl/'
  },
  {
    name: 'Askja Reizen',
    tag: 'askja',
    description: 'Client',
    image: askja,
    link: 'https://www.askja.nl/'
  },
  {
    name: 'Microsoft',
    tag: 'microsoft',
    description: 'Client',
    image: microsoft,
    link: 'https://www.microsoft.com/'
  },
  {
    name: 'TNO',
    tag: 'tno',
    description: 'Client',
    image: tno,
    link: 'https://www.tno.nl/'
  },
]

export default function useClients(allowedTags: string[] = []): Array<Client> {
  return (allClients || []).filter((client: Client) => allowedTags.length == 0 || allowedTags.includes(client.tag));
}
