import React, { useEffect, useState } from 'react';
import Container from './Container';
import Flex from './Flex';
import Hamburger from './Hamburger';
import Logo from './Logo';
import Nav from './Nav';
import HamburgerNav from './HamburgerNav';
import ScrollProgress from './ScrollProgress';
import { styled } from 'styled-components';
import useOuterClick from '../../hooks/useOuterClick';

interface HeaderProps {
  navOpen: boolean,
  setNavOpen: (toggle:boolean) => void,
}

const HeaderContainer = styled.div`
  height: var(--header-height);
`;

interface StyledHeaderProps {
  $scrolled: boolean,
}

const StyledHeader = styled.header<StyledHeaderProps>`
  background-color: var(--color-white);
  border-bottom: 1px solid ${props => props.$scrolled ? 'var(--color-iron-300)' : 'transparent'};
  height: ${props => props.$scrolled ? 'var(--header-height-scrolled)' : 'var(--header-height)'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition-duration: var(--transition-duration);
  transition-property: border-bottom, height;
  z-index: 10;
`;

export default function Header({ navOpen, setNavOpen }: HeaderProps): JSX.Element {
  const [scrolled, setScrolled] = useState(false);
  const headerRef = useOuterClick(() => setNavOpen(false));

  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else if (window.scrollY === 0) {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', scrollListener, false);
    return () => window.removeEventListener('scroll', scrollListener)
  }, []);

  return (
    <HeaderContainer>
      <StyledHeader $scrolled={scrolled} ref={headerRef}>
        <ScrollProgress />
        <Container $position="relative" $height="inherit">
          <Flex $height="inherit" $align={{ sm: 'center' }} $justify={{ sm: 'space-between' }}  >
            <Logo />
            <div> 
              <Nav display={{ sm: 'none', lg: 'flex' }} direction="horizontal" />
              <Hamburger display={{ sm: 'block', lg: 'none' }} onClick={() => setNavOpen(!navOpen)} isOpen={navOpen} />
            </div>
          </Flex>
          <HamburgerNav isOpen={navOpen} />
        </Container>
      </StyledHeader>
    </HeaderContainer>
  );
}
