import React from 'react';
import { css, styled } from 'styled-components';
import { breakpoints } from '../styled/utils';
import Baseline from './layout/Baseline';
import Col from './layout/Col';
import Container from './layout/Container';
import Grid from './layout/Grid';
import expertiseWorkWithUs from '../../assets/images/expertises/expertise-work-with-us.jpg';
import expertiseGrafischeRepubliek from '../../assets/images/expertises/expertise-grafische-republiek.jpg';

export interface ContentHighlightedProps {
  children: React.ReactNode,
  className?: string,
  image?: boolean,
  image2?: boolean,
  $light?: boolean,
}

const StyledContentHighlighted = styled.div<{ $light?: boolean }>`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, ${props => props.$light ? 'var(--color-iron-300)' : 'var(--color-downriver-400)'} 0);
  color: ${props => props.$light ? 'inherit' : 'var(--color-white)'};
  overflow: hidden;
  position: relative;
`;

interface StyledContainerProps {
  $image?: boolean,
  $image2?: boolean,
}

const StyledContainer = styled(Container) <StyledContainerProps>`
  ${props => props.$image && css`
    &::before,
    &::after {
      border-bottom-left-radius: var(--border-radius);
      border-top-left-radius: var(--border-radius);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &::after {
      background-image: url(${expertiseWorkWithUs});
      background-position: center;
      background-size: cover;
    }

      &::before {
        background-color: var(--color-downriver-400);
        z-index: 2;
        opacity: .825;
      }
  `}

  ${props => props.$image2 && css`
    &::before,
    &::after {
      border-bottom-left-radius: var(--border-radius);
      border-top-left-radius: var(--border-radius);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &::after {
      background-image: url(${expertiseGrafischeRepubliek});
      background-position: center;
      background-size: cover;
    }

      &::before {
        background-color: var(--color-downriver-400);
        z-index: 2;
        opacity: .825;
      }
  `}
`;

const StyledGrid = styled(Grid)<{ $light?: boolean }>`
  background-color: ${props => props.$light ? 'var(--color-iron-300)' : 'var(--color-downriver-400)'};
  border-bottom-left-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  padding-bottom: var(--space-xxl);
  padding-top: var(--space-xxl);
`;

const ContentCol = styled(Col)<{ $light?: boolean }>`
  &::before {
    @media ${breakpoints.md} {
      background-color: ${props => props.$light ? 'var(--color-iron-400)' : 'var(--color-downriver-400)'};
      content: '';
      height: calc(100% + var(--space-xxl) * 2);
      left: 0;
      position: absolute;
      top: calc(var(--space-xxl) * -1);
      width: 1px;
      z-index: 1;
    }
  }
`;

export default function ContentHighlighted({ image, image2, children, className, $light }: ContentHighlightedProps): JSX.Element {
  return (
    <StyledContentHighlighted $light={$light} className={className}>
      <StyledContainer $image={image} $image2={image2}>
        <StyledGrid $cols={{ sm: 4, md: 8, lg: 12 }} $columnGap="gutter" $light={$light}>
          <ContentCol $col={{ sm: 'span 4', md: '2 / span 6', lg: '3 / span 8', xl: '4 / span 6' }} $padding="gutter" $zIndex={2} $light={$light}>
            <Baseline>
              {children}
            </Baseline>
          </ContentCol>
        </StyledGrid>
      </StyledContainer>
    </StyledContentHighlighted>
  );
}
