import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  icon: IconDefinition,
  className?: string,
}

export default function Icon({ icon, className, ...props }: IconProps) {
  const [w, h, , , path] = icon.icon;

  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix={icon.prefix}
      data-icon={icon.iconName}
      className={`svg-inline--fa ${className}`}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${w} ${h}`}
      {...props}
    >
      <path fill="currentColor" d={path as string}>
      </path>
    </svg>
  );
}
