import { styled } from 'styled-components';

interface Props {
  $lines: number;
}

const Truncate = styled.div<Props>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.$lines};
  -webkit-box-orient: vertical;
  overflow: hidden; 
`;

export default Truncate;
