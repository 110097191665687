import { useRef, useEffect, MutableRefObject } from 'react';

export default function useOuterClick(callback: () => void): MutableRefObject<HTMLElement | null> {
  const ref = useRef<HTMLElement>(null);
  const callbackRef = useRef<(e: MouseEvent) => void>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && callbackRef.current && !ref.current.contains(e.target as HTMLElement)) {
        callbackRef.current(e);
      }
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  return ref;
}
