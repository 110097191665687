import React from 'react';
import { css, styled } from 'styled-components';
import { line } from '../styled/mixins';
import { breakpoints } from '../styled/utils';

interface HeadingProps {
  children: React.ReactNode,
  $bold?: boolean,
  $color?: string,
  $line?: string,
  $lineColor?: string,
  $rightAlign?: boolean,
  $baseline?: boolean,
  $counter?: string,
  $fontWeight?: number,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Heading = styled(({ $color, $line, $lineColor, $rightAlign, $bold, $counter, ...props }: HeadingProps) => <h1 {...props} />)<HeadingProps>`    
  ${props => props.$color && css`
    color: var(--color-${props.$color});
  `}

  ${props => props.$bold && css`
    font-weight: 500;
  `}

  ${props => props.$line == 'x' && css`
    ${line(props.$line, (props.theme.color || props.$lineColor))}
  `}

  ${props => props.$line == 'y' && css`
    ${line(props.$line, (props.theme.color || props.$lineColor))}
  `}

  ${props => props.$baseline && css`
    line-height: var(--line-height);
  `}

  ${props => props.$counter && css`        
    @media ${breakpoints.md} {
      position: relative;
    }

    &::after {
      display: none;

      @media ${breakpoints.md} {
        content: counter(${props.$counter});
        counter-increment: ${props.$counter};
        display: block;
        font-weight: 500;
        left: calc(var(--gutter) * -2);
        position: absolute;
        top: 0;
        transform: translateX(-100%);
      }
    }

    &:nth-child(-n+9)::after {
      content: '0'counter(${props.$counter});
    }

  `}

  ${props => props.$fontWeight && css`
    font-weight: ${props.$fontWeight};
  `}

  .font-300 {
    font-weight: 300;
  }
`;

export default Heading;
