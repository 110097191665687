import React from 'react';
import { styled } from 'styled-components';
import { faXTwitter, faLinkedin, faGithubSquare, faMastodon } from '@fortawesome/free-brands-svg-icons';
import Icon from './Icon';
import { faSquareRss } from '@fortawesome/free-solid-svg-icons';

const StyledSocials = styled.div`
  display: flex;
  font-size: var(--font-size-xl);
  line-height: 0;
  margin: 0 -0.5rem;

  // need to be at least 48x48 px see https://web.dev/tap-targets
  a {
    align-items: center;
    display: flex;
    height: 3rem;
    justify-content: center;
    width: 3rem;
  }
`;

export default function Socials(): JSX.Element {
  return (
    <StyledSocials>
      <a href="https://twitter.com/tweedegolfbv" target="_blank" rel="noopener" title="Twitter">
        <Icon icon={faXTwitter} />
      </a>
      <a href="https://nl.linkedin.com/company/tweede-golf-software-engineering" target="_blank" rel="noopener" title="LinkedIn">
        <Icon icon={faLinkedin} />
      </a>
      <a href="https://github.com/tweedegolf" target="_blank" rel="noopener" title="GitHub">
        <Icon icon={faGithubSquare} />
      </a>
      <a href="https://fosstodon.org/@tweedegolf" target="_blank" rel="noopener" title="Mastodon">
        <Icon icon={faMastodon} />
      </a>
      <a href="https://tweedegolf.nl/feed.xml" target="_blank" rel="noopener" title="RSS">
        <Icon icon={faSquareRss} />
      </a>
    </StyledSocials>
  );
}
