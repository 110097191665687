import axios from 'axios';
import { CaseCategoryById, Data, TagsById, UsersById } from './routerMiddleware';
import { Article, Case, CaseCategory, FormDetails, OsProject, Tag, User, Vacancy } from './types';
import { apiUrl, isBrowser } from './util';
// import nanomemoize from 'nano-memoize';

const get = axios.get;

// if (!isBrowser) {
//   //@ts-expect-error nanomemoize has incorrect typings
//   get = nanomemoize.nanomemoize(get, { maxAge: 60 * 1000 });
// }

interface RawArticle extends Omit<Article, 'tags'> {
  tags: Array<number>,
  users: Array<number>,
}

interface RawOsProject extends Omit<OsProject, 'tags' | 'users'> {
  tags: Array<number>,
  users: Array<number>,
}

interface RawCase extends Omit<Case, 'tags' | 'category' | 'users'> {
  category: number;
  tags: Array<number>,
  users: Array<number>,
}

export interface IndexData {
  error: boolean,
  users: Array<User>,
  caseCategories: Array<CaseCategory>,
  articles: Array<RawArticle>,
  osprojects: Array<RawOsProject>,
  cases: Array<RawCase>,
  tags: Array<Tag>,
  vacancyCount: number,
}

declare global {
  interface Window { INITIAL_DATA: Data; }
}

function populateOsprojects(items: Array<RawOsProject>, tagsById: TagsById, usersById: UsersById): Array<OsProject> {
  return items.map((osproject: RawOsProject) => ({
    ...osproject,
    tags: osproject.tags.map((id: number) => tagsById[id]),
    users: osproject.users.map((id: number) => usersById[id]).filter((user) => user),
  } as OsProject));
}

function populateCases(items: Array<RawCase>, tagsById: TagsById, usersById: UsersById, caseCategoryById: CaseCategoryById): Array<Case> {
  return items.map((c: RawCase) => ({
    ...c,
    category: caseCategoryById[c.category],
    tags: c.tags.map((id: number) => tagsById[id]),
  } as Case));
}

function populateArticle(article: RawArticle, tagsById: TagsById, usersById: UsersById) {
  return {
    ...article,
    tags: article.tags.map((id: number) => tagsById[id]),
    authors: article.users.map((id: number) => usersById[id]),
    interviewee: article.intervieweeId ? usersById[article.intervieweeId] : null,
  } as Article;
}

function populateArticles(items: Array<RawArticle>, tagsById: TagsById, usersById: UsersById): Array<Article> {
  return items.map((article: RawArticle) => populateArticle(article, tagsById, usersById));
}

export async function getIndex(): Promise<IndexData> {
  const {data} = await get<IndexData>(`${apiUrl}/api/index`);

  return data;
}

export async function getIndexData(): Promise<Data> {
  if (isBrowser && window.INITIAL_DATA) {
    return window.INITIAL_DATA;
  }

  const data = await getIndex();
  const tagsById: TagsById = data.tags.reduce((obj, tag) => ({...obj, [tag.id]: tag}), {});
  const usersById: UsersById = data.users.reduce((obj, user) => ({...obj, [user.id]: user}), {});
  const caseCategoriesById: CaseCategoryById = data.caseCategories.reduce((obj, category) => ({
    ...obj,
    [category.id]: category
  }), {});
  return {
    ...data,
    tagsById,
    usersById,
    caseCategoriesById,
    articles: populateArticles(data.articles, tagsById, usersById),
    osprojects: populateOsprojects(data.osprojects, tagsById, usersById),
    cases: populateCases(data.cases, tagsById, usersById, caseCategoriesById),
    userDetailsLoaded: false,
    osprojectDetailsLoaded: false,
    vacancies: null,
    error: undefined,
  } as Data;
}

export async function getUsers(): Promise<Array<User>> {
  const { data: users } = await get<Array<User>>(`${apiUrl}/api/employees`);

  return users;
}

export async function getArticle(article: Article): Promise<Article> {
  const { data: articleContent } = await get<Article>(`${apiUrl}/api/articles/${article.id}`);

  return {
    ...article,
    ...articleContent,
  } as Article;
}

export async function getArticlePreview(id: number, data: Data): Promise<Article> {
  const response = await get<RawArticle>(`${apiUrl}/api/articles/preview/${id}`);

  return populateArticle(response.data, data.tagsById, data.usersById);
}

export async function getVacancies(): Promise<Array<Vacancy>> {
  const { data: vacancies } = await get<Array<Vacancy>>(`${apiUrl}/api/vacancies`);

  return vacancies;
}

export async function getFormDetails(form_id: string): Promise<FormDetails> {
  try {
    const {data: formDetails} = await get<FormDetails>(`${apiUrl}/api/form/${form_id}`);
    return formDetails
  } catch (e) {
    console.error(`API error: ${e}`);

    return Promise.reject(e)
  }
}
