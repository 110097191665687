import { styled } from 'styled-components';
import Link, { LinkProps } from '../Link';
import { breakpoints } from '../../styled/utils';

const NavItem = styled(Link)<LinkProps>`
  color: inherit;
  cursor: pointer;
  display: block;
  flex-direction: column;
  gap: var(--baseline-sm);
  position: relative;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  
  @media ${breakpoints['lg']} {
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    gap: var(--space-xs);
  }

  &::after {
    background-color: var(--color-pastel-green-400);
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    transition-duration: 250ms;
    transition-property: width, opacity;
    transition-timing-function: ease-in-out;
    width: 0;
  }

  &.active,
  &:hover {
    &::after {
      opacity: 1;
      width: 100%;
    }
  }

  &.active {
    font-weight: 500;
  }

  svg {
    height: 1em;
    width: 1em;
    max-height: 1em;
    max-width: 1em;
  }
`;

export default NavItem;
