import React from 'react';
import { styled } from 'styled-components';

interface SectionsProps {
  children: React.ReactNode,
  $rowGap?: string,
}

const Sections = styled.div<SectionsProps>`
  display: grid;
  row-gap: ${props => props.$rowGap ? `var(--${props.$rowGap})` : '0'};
`;

export default Sections;
