import React from 'react';
import { css, styled } from 'styled-components';
import { BreakpointKey, Breakpoints } from '../../types';
import { breakpoints } from '../../styled/utils';

interface FlexProps {
  $align?: Breakpoints,
  children: React.ReactNode,
  $columnGap?: string,
  $direction?: Breakpoints,
  $justify?: Breakpoints,
  $rowGap?: string,
  $height?: string,
}

const Flex = styled.div<FlexProps>`
  column-gap: ${props => props.$columnGap ? `var(--${props.$columnGap})` : '0'};
  display: flex;
  height: ${props => props.$height ? props.$height : 'auto'};
  row-gap: ${props => props.$rowGap ? `var(--${props.$rowGap})` : '0'};

  ${props => props.$direction && Object.entries(props.$direction).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      flex-direction: ${value};
    }
  `)}

  ${props => props.$align && Object.entries(props.$align).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      align-items: ${value};
    }
  `)}

  ${props => props.$justify && Object.entries(props.$justify).map(([size, value]) => css`
    @media ${breakpoints[size as BreakpointKey]} {
      justify-content: ${value};
    }
  `)}
`;

export default Flex;
